// コメント位置はPrettier対策
class AppCertification {
  //FIXME 本番設定に書き換えてください。
  /**
   * アプリケーションの定数値です。
   */
  APPVERSION = "24.12.06";
  APP_CONFIG = {
    //FIXME MockModeの有効無効
    // HttpClientのgetを対象として、dummy.jsonを読み込むフラグです。
    MOCK_MODE: false,

    /**
     * API接続用設定（VDI外アクセス）
     */
    // OS環境(SV06）
    BASE_URL: "https://os-cirrus.nikkon-is.com/",
  };

  /**
   * Cognitoの設定です。
   */
  //FIXME 本番設定に書き換えてください。
  //営業所用
  COGNITO = {
    USER_POOL_ID: "ap-northeast-1_zQ5xffDY1",
    APP_CLIENT_ID: "72p6ojmgqs9e98i8jmthi4vp5t",
  };

  //荷主用
  SHIPPER_COGNITO = {
    USER_POOL_ID: "ap-northeast-1_jHhbolQzz",
    APP_CLIENT_ID: "4ojtv0ocjkvar629p6hdk2t80s",
  };

  /** datadog */
  DATADOG = {
    DD_APPLICATION_ID: "a1739e5e-15d3-4ca3-b8a5-a217e09a12fd",
    DD_CLIENT_TOKEN: "pub02fa50a4881c84b6605bdf1c1930c6f7",
    DD_SERVICE: "wms-os-pc",
    DD_ENV: "wms-os",
    DD_SESSION: 30,
  };
  /** datadog */
}

export const appCertification = new AppCertification();
